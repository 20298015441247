.sideMenu {
  background: var(--color-gray-000);
  bottom: 0;
  display: flex;
  flex-direction: column;
  max-width: 80%;
  outline: 0;
  padding: var(--spacing-05);
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition: 0.3s cubic-bezier(0.17, 0.04, 0.03, 0.94);
  width: 350px;
  z-index: 11;
}

.isOpen .sideMenu {
  transform: translateX(0);
}

.openBody {
  overflow: hidden;
}

.overlay {
  background: rgb(0 0 0 / 0%);
  bottom: 0;
  position: fixed;
  top: 0;
  transition: 0.3s cubic-bezier(0.17, 0.04, 0.03, 0.94);
  width: 100%;
  z-index: 11;
}

.isOpen.overlay {
  background: rgb(0 0 0 / 35%);
}

.logout {
  align-items: center;
  display: flex;
  font: var(--typography-label-m);
  gap: var(--spacing-03);
  justify-content: flex-end;
}

.logout svg {
  fill: none;
  height: 24px;
  stroke: var(--color-main-700);
  width: 24px;
}

.menuItems {
  overflow-y: scroll;
  padding: var(--spacing-09) 0;
}

.isMyPlace .menuItems {
  padding: var(--spacing-05) 0;
}

.menuItem {
  font: var(--typography-label-m);
  padding: var(--spacing-03) 0;
}

.languageSelector {
  align-items: center;
  background: var(--color-gray-000);
  bottom: 0;
  box-shadow: var(--layout-navbar-shadow);
  left: 0;
  padding: var(--spacing-05);
  position: absolute;
  right: 0;
  z-index: 1;
}

.languageSelectorButton {
  --button-background: var(--color-main-100);
  --button-text: var(--color-main-700);

  width: 100%;
}
